<template>
  <div class="container-fluid">
    <div class="card border-0 shadow-none">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md mb-4">
            <h3 class="fw-bold text-success mb-0">VAT Report (Malta)</h3>
          </div>
          <div class="col-auto mb-4 ms-auto">
            <date-picker
              ref="datePicker"
              v-model="range"
              is-range
              :popover="{ visibility: 'click', transition: 'fade' }"
              :input-props="{ class: 'form-control form-control-sm' }"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div class="date-picker-wrap">
                  <input
                    :value="inputValue.start + ' - ' + inputValue.end"
                    v-on:click="togglePopover"
                    class="d-inline form-control disabled"
                    placeholder="End Date"
                    readonly
                  />
                </div> </template
            ></date-picker>
          </div>
        </div>

        <div v-if="!busy" class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Client ID</th>
                <th scope="col">Client Name</th>
                <th scope="col">Retreat</th>
                <th scope="col">Arrival Date</th>
                <th scope="col">Departure Date</th>
                <th scope="col">Total</th>
                <th scope="col">Nights</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(rb, index) in data" :key="index">
                <template v-for="client in rb.booking.clients" v-key="client.id">
                  <td v-if="client.id == rb.booking.lead_client_id">{{client.id}}</td>
                  <td v-if="client.id == rb.booking.lead_client_id">{{client.name}}</td>
                </template>

                <td>{{ rb.booking.retreat }}</td>
                <td>{{ rb.arrival_date }}</td>
                <td>{{ rb.departure_date }}</td>
                <td>{{ rb.booking.total_cost.toFixed(2) }}</td>
                <td>{{ rb.booking.total_nights }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <loading v-else class="mb-4"></loading>

      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading";
import { DatePicker } from "v-calendar/lib/v-calendar.umd";

export default {
  data() {
    return {
      busy: false,
      data: [],
      popover: {
        visibility: "focus"
      },
      range: {
        start: this.$route.query.start ? this.$route.query.start : moment().startOf('month').format('YYYY-MM-DD hh:mm'),
        end: this.$route.query.end ? this.$route.query.end : moment().endOf('month').format('YYYY-MM-DD hh:mm')
      }
    };
  },
  watch: {
    range: {
      handler: function(val, old) {
        this.fetchData();
      }
    }
  },
  methods: {
    open() {
      this.$refs["datePicker"].$el.querySelector("input").focus();
    },
    fetchData() {
      this.data = [];
      this.busy = true;

      this.$axios(
        process.env.VUE_APP_API_URL +
          "/reporting/vat?start=" +
          moment(this.range.start).format("YYYY-MM-DD") +
          "&end=" +
          moment(this.range.end).format("YYYY-MM-DD")
      )
      .then(({ data }) => {
        this.data = data;
      })
      .finally(() => {
        this.busy = false;
      });
    },
  },
  mounted() {
    this.$store.sidebarOpen = false;
    this.fetchData();
  },
  components: {
    DatePicker,
    Loading
  }
};
</script>